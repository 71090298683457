import React from 'react';

import Layout from 'src/components/layout/layout';
import Schools from 'src/components/schools/schools';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const SchoolsPage = () => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="Schools" />
      <Schools />
    </Layout>
  );
};

export default SchoolsPage;
